<template>
	<div class="container flex f-column f-align f-justifyB">
		<div class="f-center set-box" @click="getVersion">
			<img src="../assets/image/qt-gx@2x.png">
			<div class="flex f-column">
				<span>版本更新</span>
				<span class="font60">{{$version}}</span>
			</div>
		</div>
		<div class="f-center set-box" @click="smallHandle">
			<img src="../assets/image/qt-zxh@2x.png">
			<div class="flex f-column" style="letter-spacing: 10px;">
				<span>最小化</span>
				<span class="font60">minimize</span>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				newVersion: '',
				cabtype: '',
				downloadLink: ''
			};
		},
		mounted() {
			if (this.$route.query.net == 'no') {
				this.$emit('getTitle', {
					title: '系统设置',
					pageUrl: '/noNet'
				})
			} else {
				this.$emit('change', '')
			}
			this.cabtype = localStorage.getItem('cabtype')
			this.getLinkInfo()
		},
		methods: {
			getLinkInfo() {
				this.$post('/DownloadLinkSetUp/list').then(res => {
					if (res.code == 200 && res.data.length > 0) {
						this.downloadLink = res.data[0].downloadLink
					}
				})
			},
			versionUpdate(versionContenet) {
				let _this = this

				if (!this.newVersion) {
					this.$toast('暂无最新版本，无需更新')
					return
				}

				try {
					AndroidInterFace.updateApp(this.downloadLink, versionContenet);
				} catch (e) {
					localStorage.clear()
					sessionStorage.clear()
					this.$router.replace('/')
					location.reload()
				}
			},
			getVersion() {
				this.$post('/Version/list').then(res => {
					if (res.code == 0 && res.data.length > 0) {
						let newV = res.data[0].versionNumber.split('.')
						let oldV = this.$version.split('.')
						let newN = parseInt(newV.join(''))
						let oldN = parseInt(oldV.join(''))
						if (newN > oldN) {
							this.newVersion = res.data[0].versionNumber
							this.versionUpdate(res.data[0].versionContenet)
						} else {
							this.$toast('当前已是最新版本');
						}
					}
				})
			},
			smallHandle() {
				if (this.cabtype == 'android') {
					AndroidInterFace.minimize()
				} else {
					window.close()
				}
			}
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding: 55px 0;

		.set-box {
			width: 800px;
			height: 300px;
			background: linear-gradient(270deg, #4E8CFF 0%, #216FFF 100%);
			border-radius: 30px;
			// padding: 0 170px 0 110px;
			font-size: 80px;
			line-height: 112px;
			color: #FFFFFF;

			img {
				width: 135px;
				height: 135px;
				margin-right: 80px;
			}

			&:last-child {
				background: rgba(247, 0, 89, 1);
			}
		}
	}
</style>
